import React from 'react'

import AppContext from './Context'
import sessionAPI from '../../services/session'

class AppProvider extends React.Component {

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  loadFromStorage = () => {
    Promise.all([
      this.loadSession()
    ]).then(() => this.setState({ initialized: true }))
  }

  loadSession = () => {
    return new Promise((resolve, reject) => {
      const token = this.getStorage('sessiontoken')
      if (token) {
        this.setState({ token: token }, () => {
          sessionAPI.me(this.state).then(result => {
            if (result && result.user) {
              this.setState({ user: result.user }, () => {
                resolve(true)
              })
            } else {
              resolve(true)
            }
          })
        })
      } else {
        resolve(true)
      }
    })
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  getStorage = (name) => {
    return localStorage.getItem(`shopupnorth-frontend-admin-${name}`)
  }

  setStorage = (name, value) => {
    localStorage.setItem(`shopupnorth-frontend-admin-${name}`, value)
  }

  unsetStorage = (name) => {
    localStorage.removeItem(`shopupnorth-frontend-admin-${name}`)
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  onLogin = (user, token) => {
    this.setState({ user: user, token: token }, () => {
      this.setStorage('sessiontoken', token)
    })
  }

  onLogout = () => {
    this.setState({ user: null, token: null }, () => {
      this.unsetStorage('sessiontoken')
    })
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  onSearch = (query) => {
    this.setState({ search: query })
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  addNotification = (title, icon, content, type) => {
    const { notifications } = this.state

    notifications.push({
      title: title,
      icon: icon,
      content: content,
      type: type
    })

    this.setState({ notifications: notifications }, () => {
      setTimeout(() => {
        const { notifications } = this.state
        notifications.shift()
        this.setState({ notifications: notifications })
      }, 3000)
    })
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(props) {
    super(props)

    this.state = {
      initialized: false,
      user: null,
      token: null,
      onLogin: this.onLogin,
      onLogout: this.onLogout,
      ////////////////////////////////////////////////////////////////////////////////////////////////////
      search: '',
      onSearch: this.onSearch,
      ////////////////////////////////////////////////////////////////////////////////////////////////////
      notifications: [],
      addNotification: this.addNotification,
      ////////////////////////////////////////////////////////////////////////////////////////////////////
      currentSidebarPage: null,
      setCurrentSidebarPage: (cb) => this.setState({ currentSidebarPage: cb })
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  componentDidMount() {
    this.loadFromStorage()
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <AppContext.Provider value={ this.state }>
        {this.props.children}
      </AppContext.Provider>
    )
  }
}

export default AppProvider
