import * as config from '../config/config.json'

const queryString = require('query-string')
const app = require("../../package.json");

const checkHttpStatus = (response) => {
  if (response.status >= 400) {
    return {
      error: response.status
    }
  }
  return response.json()
}

export const apiURL = () => {
  return config.default.api.url
}

export const callAPI = (context, path, method, params) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-Application-Name': app.name,
    'X-Application-Version': app.version
  }

  if (context && context.token) headers.Authorization = `Token ${context.token}`

  return fetch(
    `${config.default.api.url}${path}?${method.toUpperCase() === 'GET' ? queryString.stringify(params) : ''}`,
    {
      method: method,
      headers: headers,
      body: method.toUpperCase() !== 'GET' ? JSON.stringify(params) : null
    }
  )
  .then(response => checkHttpStatus(response))
}
