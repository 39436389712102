import { callAPI } from './api'

export function login(username, password) {
  return callAPI(null, '/me', 'POST', {
    user: {
      email: username,
      password: password
    }
  })
}

export function me(context) {
  return callAPI(context, '/me', 'GET')
}

export default {
  login,
  me
}
