// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-cadeaulijsten-js": () => import("./../../../src/pages/cadeaulijsten.js" /* webpackChunkName: "component---src-pages-cadeaulijsten-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-klanten-js": () => import("./../../../src/pages/klanten.js" /* webpackChunkName: "component---src-pages-klanten-js" */),
  "component---src-pages-marketing-index-js": () => import("./../../../src/pages/marketing/index.js" /* webpackChunkName: "component---src-pages-marketing-index-js" */),
  "component---src-pages-marketing-picker-js": () => import("./../../../src/pages/marketing/picker.js" /* webpackChunkName: "component---src-pages-marketing-picker-js" */),
  "component---src-pages-merken-index-js": () => import("./../../../src/pages/merken/index.js" /* webpackChunkName: "component---src-pages-merken-index-js" */),
  "component---src-pages-merken-rapportage-js": () => import("./../../../src/pages/merken/rapportage.js" /* webpackChunkName: "component---src-pages-merken-rapportage-js" */),
  "component---src-pages-orders-js": () => import("./../../../src/pages/orders.js" /* webpackChunkName: "component---src-pages-orders-js" */),
  "component---src-pages-printer-js": () => import("./../../../src/pages/printer.js" /* webpackChunkName: "component---src-pages-printer-js" */),
  "component---src-pages-producten-js": () => import("./../../../src/pages/producten.js" /* webpackChunkName: "component---src-pages-producten-js" */),
  "component---src-pages-reports-index-js": () => import("./../../../src/pages/reports/index.js" /* webpackChunkName: "component---src-pages-reports-index-js" */),
  "component---src-pages-reports-store-js": () => import("./../../../src/pages/reports/store.js" /* webpackChunkName: "component---src-pages-reports-store-js" */),
  "component---src-pages-reports-webshop-js": () => import("./../../../src/pages/reports/webshop.js" /* webpackChunkName: "component---src-pages-reports-webshop-js" */),
  "component---src-pages-vouchers-js": () => import("./../../../src/pages/vouchers.js" /* webpackChunkName: "component---src-pages-vouchers-js" */),
  "component---src-pages-winkel-index-js": () => import("./../../../src/pages/winkel/index.js" /* webpackChunkName: "component---src-pages-winkel-index-js" */),
  "component---src-pages-zoeken-js": () => import("./../../../src/pages/zoeken.js" /* webpackChunkName: "component---src-pages-zoeken-js" */)
}

